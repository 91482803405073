import React from 'react';
import { LinkButton } from '@fashionphile/component-library';
import { convertButtonString } from '../../../utilities/helpers';
import { EditorialCopyBlockData } from '../../../types/DynamicLandingPageReducer';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  INLINES,
  Node,
  MARKS,
} from '@contentful/rich-text-types';
import { Bold } from '../../../components/contentful/text';
import classNames from 'classnames';

const EditorialCopyBlock = ({
  data,
  index,
}: {
  data: EditorialCopyBlockData;
  index: number;
}): React.ReactElement => {
  const eyebrowCopy = data.fields?.eyebrowCopy;
  const targetAttributeValue = data.fields?.linkOutToAnotherTab
    ? '_blank'
    : '_self';
  const cta = data.fields.cta?.fields;
  const align = (data.fields.textAlignment || '').toLowerCase();
  const editorialContent = data.fields.editorialContent || '';
  const headlineStyle = (
    data.fields.headlineStyle || ''
  ).toLowerCase();
  const isStandard = headlineStyle === 'standard';
  const noPaddingTop = index === 0 ? 'noPaddingTop' : '';

  const options = {
    renderMark: {
      [MARKS.BOLD]: (
        text: React.ReactElement,
      ): React.ReactElement => <Bold className="bold">{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => <p>{children}</p>,
      [INLINES.HYPERLINK]: (
        node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <a
          className={`hyperLink`}
          href={node.data?.uri || ''}
          target={targetAttributeValue}
          rel="noreferrer"
          tabIndex={0}
        >
          {children}
        </a>
      ),
      [BLOCKS.HEADING_1]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <h1 className={classNames('h1-large', headlineStyle)}>
          {children}
        </h1>
      ),
      [BLOCKS.HEADING_2]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <h2 className={headlineStyle}>{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <h3 className={classNames(isStandard && 'standardStyle')}>
          {children}
        </h3>
      ),
      [BLOCKS.HEADING_4]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <h4 className={classNames(isStandard && 'standardStyle')}>
          {children}
        </h4>
      ),
      [BLOCKS.HEADING_5]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <h5 className={classNames(isStandard && 'standardStyle')}>
          {children}
        </h5>
      ),
      [BLOCKS.HEADING_6]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <h6 className={classNames(isStandard && 'standardStyle')}>
          {children}
        </h6>
      ),
      [BLOCKS.OL_LIST]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => {
        return <ol className="olStyles">{children}</ol>;
      },
      [BLOCKS.UL_LIST]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => {
        return <ul className="ulStyles">{children}</ul>;
      },
    },
  };

  return (
    <div className={`contentBlockWrapper ${noPaddingTop}`}>
      <div className="row editorialCopyBlock">
        <div className="col-1 placeholder" />
        <div className="col-10 col-xs-12">
          <div className={`pageContent ${align}`}>
            <>
              <p className="eyebrow">{eyebrowCopy}</p>
              <>
                {editorialContent &&
                  documentToReactComponents(
                    editorialContent,
                    options as Options,
                  )}
              </>
            </>
            <>
              {cta && (
                <div className="buttonContainer">
                  <LinkButton
                    target={targetAttributeValue}
                    content={cta.textToDisplay}
                    size="lg"
                    variant={convertButtonString(cta.color)}
                    ariaLabel={cta?.textToDisplay || 'button'}
                    href={cta.linkUrl}
                  />
                </div>
              )}
            </>
          </div>
        </div>
        <div className="col-1 placeholder" />
      </div>
    </div>
  );
};

export default EditorialCopyBlock;
