import React from 'react';
import { renderRichText } from '../utils';
import { LinkButton } from '@fashionphile/component-library';
import { convertButtonString } from '../../../utilities/helpers';
import classNames from 'classnames';
import { VideoComponentData } from 'src/types/DynamicLandingPageReducer';
import { useMediaQuery } from 'src/utilities/useMediaQuery';
import { MOBILE_PORTRAIT_BREAKPOINT } from 'src/utilities/constants';
import {
  Node,
  BLOCKS,
  MARKS,
  INLINES,
} from '@contentful/rich-text-types';
import { Bold } from '../../contentful/text';
import {
  Options,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';

const Video = ({
  data,
}: {
  data: VideoComponentData;
  index?: number;
}): React.ReactElement => {
  const backgroundColor = `${(
    data.fields.backgroundColor || 'white'
  ).toLowerCase()}-bg`;
  const headline = data.fields.headline || '';
  const targetAttributeValue = data.fields?.linkOutToAnotherTab
    ? '_blank'
    : '_self';
  const subCopyRichText = data.fields.subcopyRichText || '';
  const fineprintCta = data.fields.fineprintCta || '';
  const cta = data.fields.cta?.fields;
  const linkUrl = cta?.linkUrl || '';
  const videoAlignment = (
    data.fields.videoAlignment || ''
  ).toLowerCase();
  const videoLink =
    data.fields.videoLink ||
    'https://www.youtube.com/watch?v=K-seI-d9mbw';
  let videoLinkUrl = new URL(videoLink);
  const videoLinkId = new URLSearchParams(videoLinkUrl.search).get(
    'v',
  );

  const isMobilePortrait = useMediaQuery(
    `(max-width: ${MOBILE_PORTRAIT_BREAKPOINT}px)`,
  );

  const options = {
    renderMark: {
      [MARKS.BOLD]: (
        text: React.ReactElement,
      ): React.ReactElement => <Bold className="bold">{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => <p>{children}</p>,
      [INLINES.HYPERLINK]: (
        node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <a
          className={`hyperLink `}
          href={node.data?.uri || ''}
          target={targetAttributeValue}
          rel="noreferrer"
          tabIndex={0}
        >
          {children}
        </a>
      ),
      [BLOCKS.HEADING_1]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <h1 className={classNames('h1-large')}>{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => <h5>{children}</h5>,
      [BLOCKS.HEADING_6]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => <h6>{children}</h6>,
      [BLOCKS.OL_LIST]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => {
        return <ol className="olStyles">{children}</ol>;
      },
      [BLOCKS.UL_LIST]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => {
        return <ul className="ulStyles">{children}</ul>;
      },
    },
  };

  let showVideoAndContent = <></>;
  if (videoAlignment === 'left') {
    showVideoAndContent = (
      <>
        <div
          className={classNames(
            'col-6 col-xs-12',
            'videoHeight',
            `${videoAlignment}`,
          )}
        >
          <iframe
            src={`https://www.youtube.com/embed/${videoLinkId}/?controls=0`}
            title="YouTube video player"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
        </div>
        <div
          className={classNames(
            'col-6 col-xs-12',
            'textBlockStyles',
            `${videoAlignment}`,
          )}
        >
          <div className="col-12">
            {headline && (
              <h2
                className={classNames(`headln ${videoAlignment}`)}
                data-testid="video-header"
              >
                {headline}
              </h2>
            )}
          </div>

          {subCopyRichText && (
            <div>
              {subCopyRichText &&
                documentToReactComponents(
                  subCopyRichText,
                  options as Options,
                )}
            </div>
          )}
          {cta && (
            <div className="btn">
              <LinkButton
                target={targetAttributeValue}
                content={cta?.textToDisplay}
                size="lg"
                variant={convertButtonString(cta?.color)}
                ariaLabel={cta?.textToDisplay || 'button'}
                href={linkUrl}
                tabIndex={0}
              />
            </div>
          )}
          {fineprintCta && (
            <div className="fineprintCTA">
              {renderRichText(fineprintCta, targetAttributeValue)}
            </div>
          )}
        </div>
      </>
    );
  } else if (videoAlignment === 'right') {
    showVideoAndContent = (
      <>
        {!isMobilePortrait ? (
          <>
            <div
              className={classNames(
                'col-6 col-xs-12',
                'textBlockStyles',
                'left',
              )}
            >
              <div className="col-12">
                {headline && (
                  <h2
                    className={classNames(`headln ${videoAlignment}`)}
                    data-testid="video-header"
                  >
                    {headline}
                  </h2>
                )}
              </div>
              {subCopyRichText && (
                <div>
                  {subCopyRichText &&
                    renderRichText(
                      subCopyRichText,
                      targetAttributeValue,
                    )}
                </div>
              )}
              {cta && (
                <div className="btn">
                  <LinkButton
                    target={targetAttributeValue}
                    content={cta?.textToDisplay}
                    size="lg"
                    variant={convertButtonString(cta?.color)}
                    ariaLabel={cta?.textToDisplay || 'button'}
                    href={linkUrl}
                    tabIndex={0}
                  />
                </div>
              )}
              {fineprintCta && (
                <div className="fineprintCTA">
                  {renderRichText(fineprintCta, targetAttributeValue)}
                </div>
              )}
            </div>
            <div
              className={classNames(
                'col-6 col-xs-12',
                'videoHeight',
                `${videoAlignment}`,
              )}
            >
              <iframe
                src={`https://www.youtube.com/embed/${videoLinkId}/?controls=0`}
                title="YouTube video player"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
              ></iframe>
            </div>
          </>
        ) : (
          <>
            <div
              className={classNames(
                'col-6 col-xs-12',
                'videoHeight',
                `${videoAlignment}`,
              )}
            >
              <iframe
                src={`https://www.youtube.com/embed/${videoLinkId}/?controls=0`}
                title="YouTube video player"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
              ></iframe>
            </div>
            <div
              className={classNames(
                'col-6 col-xs-12',
                'textBlockStyles',
                'left',
              )}
            >
              <div className="col-12">
                {headline && (
                  <h2
                    className={classNames(`headln ${videoAlignment}`)}
                    data-testid="video-header"
                  >
                    {headline}
                  </h2>
                )}
              </div>
              {subCopyRichText && (
                <div>
                  {subCopyRichText &&
                    renderRichText(
                      subCopyRichText,
                      targetAttributeValue,
                    )}
                </div>
              )}
              {cta && (
                <div className="btn">
                  <LinkButton
                    target={targetAttributeValue}
                    content={cta?.textToDisplay}
                    size="lg"
                    variant={convertButtonString(cta?.color)}
                    ariaLabel={cta?.textToDisplay || 'button'}
                    href={linkUrl}
                    tabIndex={0}
                  />
                </div>
              )}
              {fineprintCta && (
                <div className="fineprintCTA">
                  {renderRichText(fineprintCta, targetAttributeValue)}
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  } else if (videoAlignment === 'center') {
    showVideoAndContent = (
      <>
        <div className="col-12">
          {headline && (
            <h2
              className={classNames(`headln ${videoAlignment}`)}
              data-testid="video-header"
            >
              {headline}
            </h2>
          )}
        </div>
        <div className="col-3 col-md-1"></div>
        <div className="col-6 col-md-10 videoHeight">
          <iframe
            src={`https://www.youtube.com/embed/${videoLinkId}/?controls=0`}
            title="YouTube video player"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
          <div className="col-3"></div>
        </div>
        <div className="col-3 col-md-1"></div>

        <div className="col-3 col-md-1"></div>
        <div
          className={classNames(
            'col-6 col-md-10',
            'textBlockStyles',
            `${videoAlignment}`,
          )}
        >
          {subCopyRichText && (
            <div>
              {subCopyRichText &&
                renderRichText(subCopyRichText, targetAttributeValue)}
            </div>
          )}
          {cta && (
            <div className="btn">
              <LinkButton
                target={targetAttributeValue}
                content={cta?.textToDisplay}
                size="lg"
                variant={convertButtonString(cta?.color)}
                ariaLabel={cta?.textToDisplay || 'button'}
                href={linkUrl}
                tabIndex={0}
              />
            </div>
          )}
          {fineprintCta && (
            <div className="fineprintCTA">
              {renderRichText(fineprintCta, targetAttributeValue)}
            </div>
          )}
        </div>
        <div className="col-3 col-md-1"></div>
      </>
    );
  }

  return (
    <>
      <div className={`videoModuleContainer  ${backgroundColor}`}>
        <div className="videoModuleWrapper container row">
          {showVideoAndContent}
        </div>
      </div>
    </>
  );
};

export default Video;
