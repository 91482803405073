import React from 'react';
import ImageBanner from './contentBlocks/imageBanner';
import TextBanner from './contentBlocks/textBanner';
import Video from './contentBlocks/video';
import ContentTiles from './contentBlocks/contentTiles';
import ImagePlusText from './contentBlocks/imagePlusText';
import EditorialCopyBlock from './contentBlocks/editorialCopyBlock';
import TextTile from './contentBlocks/textTile';
import Highlights from './contentBlocks/highlights';
import GiftGuide from './contentBlocks/giftGuide';
import HorizontalImage from './contentBlocks/horizontalImage';
import HeroBanner from './contentBlocks/heroBanner';
import HeroBannerHalfHeight from './contentBlocks/heroBannerHalfHeight';
import ThumbnailHighlights from './contentBlocks/thumbnailHighlights';
import IconBox from './contentBlocks/iconBox';
import DLPCarousel from './contentBlocks/carousel';
import {
  DynamicLandingPgData,
  ContentBlocks,
} from '../../types/Store';

const contentBlockComponents = {
  carousel: DLPCarousel,
  contentTilesGrid: ContentTiles,
  editorialCopyBlock: EditorialCopyBlock,
  giftGuide: GiftGuide,
  heroBanner: HeroBanner,
  heroBannerHalfHeight: HeroBannerHalfHeight,
  highlights: Highlights,
  horizontalImage: HorizontalImage,
  iconBox: IconBox,
  imageBanner: ImageBanner,
  imageText: ImagePlusText,
  shoppableTiles: HorizontalImage,
  textBanner: TextBanner,
  textTile: TextTile,
  thumbnailHighlights: ThumbnailHighlights,
  video: Video,
};

const RenderContentBlocks = ({
  contentBlockIdArr,
  contentBlocks,
}: {
  contentBlockIdArr: any;
  contentBlocks: ContentBlocks[];
}): React.ReactElement => {
  return contentBlockIdArr.map(
    (
      variation: keyof typeof contentBlockComponents,
      index: number,
    ) => {
      const ContentBlock = contentBlockComponents[variation];
      if (typeof ContentBlock === 'undefined') {
        return null;
      }
      let applyStyles = 'container';
      if (
        variation === 'heroBanner' ||
        variation === 'heroBannerHalfHeight' ||
        variation === 'video'
      ) {
        applyStyles = 'fluid ';
      }
      const bottomMostContentBlock =
        index === contentBlockIdArr.length - 1;

      return (
        <div
          key={`${variation}-${index}`}
          className={`${applyStyles} dynamicLandingPage ${
            bottomMostContentBlock ? 'paddingBottom' : ''
          }`}
        >
          <ContentBlock data={contentBlocks[index]} index={index} />
        </div>
      );
    },
  );
};

const Container = ({
  dynamicLandingPgData,
}: {
  dynamicLandingPgData: DynamicLandingPgData;
}): React.ReactElement => {
  const { contentBlocks, contentBlockIdArr } = dynamicLandingPgData;

  return (
    <RenderContentBlocks
      contentBlockIdArr={contentBlockIdArr}
      contentBlocks={contentBlocks}
    />
  );
};

export default Container;
